import React, { useState } from "react";

import Preview from "@components/shared/preview";
import { Container, GridContainer } from "@util/standard";
import { graphql, useStaticQuery } from "gatsby";
import { Query } from "@graphql-types";

function IndustryGrid() {
  const { allSanityIndustry }: Query = useStaticQuery(graphql`
    {
      allSanityIndustry {
        edges {
          node {
            _id
            industryName
            displayStyle
            ctaLink {
              url
              linktext
            }
            industryFeatureImage {
              asset {
                fluid(maxWidth: 3000) {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  ...GatsbySanityImageFluid
                }
              }
            }
            containImage
            subText {
              _rawContent
            }
          }
        }
      }
    }
  `);

  const [currentExpandedTab, setCurrentExpandedTab] = useState<number>();

  return (
    <Container margin="0 -3px 0 -3px">
      <GridContainer
        gridTemplateColumns="repeat(3,1fr)"
        margin="0 auto"
        tabletRepeat={1}
      >
        {allSanityIndustry.edges.map((industry, index) => {
          const {
            industryName,
            industryFeatureImage,
            ctaLink,
            subText,
            displayStyle,
            containImage,
          } = industry.node;
          if (industry == null) {
            return null;
          }
          return (
            <div onClick={() => setCurrentExpandedTab(index)}>
              <Preview
                name={industryName}
                subText={subText}
                featureImage={industryFeatureImage}
                ctaLink={ctaLink}
                containImage={containImage}
                index={index}
                displayStyle={displayStyle}
                key={industry.node._id}
                enableExpandedView
                disableExpandedVIew={index !== currentExpandedTab}
              />
            </div>
          );
        })}
      </GridContainer>
    </Container>
  );
}

export default IndustryGrid;
