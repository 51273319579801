import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "@util/standard";
import Hero from "@components/shared/hero/hero";
import IndustryGrid from "@components/inTheWild/industryGrid";
import SEO from "@components/shared/seo";

function AluminiumInTheWild() {
  const { sanityInTheWild } = useStaticQuery(graphql`
    {
      sanityInTheWild {
        heroBlock {
          heroHeader
          heroVideo {
            vimeoUrl
            uploadedVideo {
              asset {
                url
              }
            }
          }
          useVideo
          ctaScrollButtonText
          heroSubText {
            _rawContent
          }
          heroBackgroundImage {
            asset {
              fluid(maxWidth: 4000) {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
            }
          }
        }
        seoBlock {
          pageTitle
          slug {
            current
          }
          ogImage {
            asset {
              fluid {
                srcWebp
                srcSet
                srcSetWebp
                src
              }
            }
          }
          pageKeyWords
          pageDescription
        }
      }
    }
  `);

  return (
    <>
      <SEO seoData={sanityInTheWild.seoBlock} slug={"/aluminium-in-the-wild"} />
      <Hero heroContent={sanityInTheWild.heroBlock} headerIsVisible />
      <IndustryGrid />
    </>
  );
}

export default AluminiumInTheWild;
